/* You can add global styles to this file, and also import other style files */
//@import '@angular/material/prebuilt-themes/pink-bluegrey.css';
//@import '@angular/material/prebuilt-themes/purple-green.css';
//@import '@angular/material/prebuilt-themes/indigo-pink.css';
//@import '@angular/material/prebuilt-themes/deeppurple-amber';

[DevFrame] {
  border: 2px solid rgb(0, 255, 136);
}

[DevFrameRed] {
  border: 2px solid rgb(255, 47, 0);
}

.mat-expansion-panel-content > .mat-expansion-panel-body {
  padding: 0 !important;
}

@import "~@angular/material/theming";

@include mat-core();

//Add your desired themes to this map.
$themes-map: (
  pink-bluegrey: (
    // background: mat-palette($mat-blue-grey, 50),
    primary: mat-palette($mat-blue-grey, 600),
    accent: mat-palette($mat-teal, 300),
    warn: mat-palette($mat-red),
  ),
  purple-green: (
    // background: mat-palette($mat-blue-grey, 50),
    primary: mat-palette($mat-teal, 500),
    accent: mat-palette($mat-lime, 600),
    warn: mat-palette($mat-red),
  ),
  purple-green1: (
    // background: mat-palette($mat-blue-grey, 50),
    primary: mat-palette($mat-blue, 500),
    accent: mat-palette($mat-yellow, 600),
    warn: mat-palette($mat-red),
  ),
  purple-green8: (
    // background: mat-palette($mat-blue-grey, 50),
    primary: mat-palette($mat-blue-grey, 500),
    accent: mat-palette($mat-blue, 600),
    warn: mat-palette($mat-red),
  ),
  purple-green9: (
    // background: mat-palette($mat-blue-grey, 50),
    primary: mat-palette($mat-yellow, 500),
    accent: mat-palette($mat-blue, A400),
    warn: mat-palette($mat-pink, A200),
  ),
  purple-green10: (
    // background: mat-palette($mat-blue-grey, 50),
    primary: mat-palette($mat-purple, 400),
    accent: mat-palette($mat-cyan, 600),
    warn: mat-palette($mat-red),
  ),
);

@each $css-class, $theme in $themes-map {
  //$background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  // Light-themes:
  .#{$css-class} {
    $bg-color: map_get($mat-blue-grey, 50);
    $bg-card-color: map_get($mat-blue-grey, 50);

    $mat-theme: mat-light-theme($primary, $accent, $warn);

    @include angular-material-theme($mat-theme);
  }

  // Dark-themes:

  // Defaults
  // $mat-dark-theme-background: (
  //   status-bar: black,
  //   app-bar:    map-get($mat-grey, 900),
  //   background: #303030,
  //   hover:      rgba(white, 0.04), // TODO(kara): check style with Material Design UX
  //   card:       map-get($mat-grey, 800),
  //   dialog:     map-get($mat-grey, 800),
  //   disabled-button: rgba(white, 0.12),
  //   raised-button: map-get($mat-grey, 800),
  //   focused-button: $light-focused,
  //   selected-button: map-get($mat-grey, 900),
  //   selected-disabled-button: map-get($mat-grey, 800),
  //   disabled-button-toggle: black,
  //   unselected-chip: map-get($mat-grey, 700),
  //   disabled-list-option: black,
  //   tooltip: map-get($mat-grey, 700),
  // );

  .#{$css-class}-dark {
    $bg-color: map_get($mat-grey, 900);
    $bg-card-color: map_get($mat-grey, 800);
    $bg-dialog-color: map_get($mat-grey, 800);

    // Background palette for dark themes.
    $mat-dark-theme-overrides: (
      status-bar: black,
      app-bar: map-get($mat-grey, 900),
      background: #111111,
      hover: rgba(white, 0.04),
      // TODO(kara): check style with Material Design UX
      card:
        map-get($mat-grey, 900),
      dialog: #161616,
      disabled-button: rgba(white, 0.12),
      raised-button: map-get($mat-grey, 800),
      focused-button: $light-focused,
      selected-button: map-get($mat-grey, 900),
      selected-disabled-button: map-get($mat-grey, 800),
      disabled-button-toggle: black,
      unselected-chip: map-get($mat-grey, 700),
      disabled-list-option: black,
      tooltip: map-get($mat-grey, 700),
    );

    $theme: mat-dark-theme($primary, $accent, $warn);

    $bg-object: map-get($theme, background);

    $background: map_merge(
      $bg-object,
      $mat-dark-theme-overrides
    ); //commenting this line will disable background overides!

    $theme: map_merge(
      $theme,
      (
        background: $background,
      )
    );

    @include angular-material-color($theme);
  }

  // Below stylesheets have been used in theme-switcher-tool (in the toolbar):
  .theme-primary.#{$css-class} {
    background-color: mat-color($primary);
    //  border: 1px solid rgb(0, 0, 0);
  }
  .theme-accent.#{$css-class} {
    background-color: mat-color($accent);
    //  border: 1px solid rgb(0, 0, 0);
  }
  .theme-warn.#{$css-class} {
    background-color: mat-color($warn);
    // border: 1px solid rgb(0, 0, 0);
  }

  // below are custom classes for use globally to access the current theme color
  .theme-fg-primary.#{$css-class} {
    color: mat-color($primary);
  }

  .theme-bg-primary.#{$css-class} {
    background-color: mat-color($primary);
  }

  .theme-fg-accent.#{$css-class} {
    color: mat-color($accent);
  }

  .theme-bg-accent.#{$css-class} {
    background-color: mat-color($accent);
  }

  .theme-fg-warn.#{$css-class} {
    color: mat-color($warn);
  }

  .theme-bg-warn.#{$css-class} {
    background-color: mat-color($warn);
  }

  .ngx-charts.#{$css-class} {
    text {
      fill: mat-color($warn);
    }
  }

  .ngx-charts .gridline-path {
    stroke: gray !important;
    stroke-width: 0.5px !important;
    stroke-opacity: 50;
  }

  .ngx-charts .line-highlight {
    display: block !important;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Zen Dots";
}
